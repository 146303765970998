import React, { useContext, useEffect, useState } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { isNullOrUndefined } from '@utils/utils';
import { AuthenticatedUserContext } from './AuthenticatedUser/AuthenticatedUser';

const AppSubmenu = (props) => {
  const { className, items, onMenuItemClick, onRootItemClick, root, layoutMode, menuActive, parentMenuItemActive } =
    props;

  const [activeIndex, setActiveIndex] = useState(null);

  const onItemClick = (event, item, index) => {
    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    if (root && onRootItemClick) {
      onRootItemClick({
        originalEvent: event,
        item: item,
      });
    }

    //execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
      event.preventDefault();
    }

    if (index === activeIndex) setActiveIndex(null);
    else setActiveIndex(index);

    if (onMenuItemClick) {
      onMenuItemClick({
        originalEvent: event,
        item: item,
      });
    }
  };

  const onKeyDown = (event, item, index) => {
    if (event.key === 'Enter') {
      onItemClick(event, item, index);
    }
  };

  const onMenuItemMouseEnter = (index) => {
    if (root && menuActive && isHorizontalOrSlim() && !isMobile()) {
      setActiveIndex(index);
    }
  };

  const isHorizontalOrSlim = () => {
    return layoutMode === 'horizontal' || layoutMode === 'slim';
  };

  const isMobile = () => {
    return window.innerWidth <= 640;
  };

  const renderLinkContent = (item) => {
    let badge = item.badge && <span className="menuitem-badge">{item.badge}</span>;

    return (
      <>
        {item.icon && <i className={item.icon}></i>}
        <span>{item.label}</span>
        {badge}
      </>
    );
  };

  const renderLink = (item, i) => {
    let content = renderLinkContent(item);

    if (item.to) {
      return (
        <Link href={item.to}>
          <a
            role="menuitem"
            onClick={(e) => onItemClick(e, item, i)}
            target={item.target}
            onMouseEnter={(e) => onMenuItemMouseEnter(i)}
            className={item.styleClass}
          >
            {content}
          </a>
        </Link>
      );
    } else {
      return (
        <div
          className={classNames('layout-menuitem-root-text', item.styleClass)}
          role="menuitem"
          tabIndex={item.url ? -1 : 0}
          onKeyDown={(e) => onKeyDown(e, item, i)}
          onMouseEnter={(e) => onMenuItemMouseEnter(i)}
        >
          {content}
        </div>
      );
    }
  };

  return (
    <ul role="menu" className={className}>
      {items &&
        items
          .filter((t) => t.render)
          .map((item, i) => {
            let active = activeIndex === i;
            let styleClass = classNames(item.badgeStyleClass, {
              'active-menuitem': active,
            });

            return (
              <li className={styleClass} key={i} role="none">
                {renderLink(item, i)}
                <CSSTransition classNames="layout-submenu" timeout={{ enter: 400, exit: 400 }} in={active}>
                  <AppSubmenu
                    items={item.items}
                    onMenuItemClick={onMenuItemClick}
                    layoutMode={layoutMode}
                    menuActive={menuActive}
                    parentMenuItemActive={active}
                  />
                </CSSTransition>
              </li>
            );
          })}
    </ul>
  );
};

AppSubmenu.defaultProps = {
  className: null,
  items: null,
  onMenuItemClick: null,
  onRootItemClick: null,
  root: false,
  layoutMode: null,
  menuActive: false,
  parentMenuItemActive: false,
};

AppSubmenu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  onMenuItemClick: PropTypes.func,
  onRootItemClick: PropTypes.func,
  root: PropTypes.bool,
  layoutMode: PropTypes.string,
  menuActive: PropTypes.bool,
  parentMenuItemActive: PropTypes.bool,
};

const AppMenu = (props) => {
  const { model, onMenuItemClick, onRootMenuItemClick, layoutMode, active } = props;
  const { loggedUser } = useContext(AuthenticatedUserContext);

  const permittedModules = loggedUser?.modules.map((t) => t.moduleType) || [];

  return (
    <AppSubmenu
      items={model
        .filter((t) => t != null)
        .filter((t) => isNullOrUndefined(t.requiredModule) || permittedModules.indexOf(t.requiredModule) >= 0)}
      className="ultima-menu ultima-main-menu clearfix"
      menuActive={active}
      onRootItemClick={onRootMenuItemClick}
      onMenuItemClick={onMenuItemClick}
      root={true}
      layoutMode={layoutMode}
      parentMenuItemActive={true}
    />
  );
};

// @ts-ignore
export default AppMenu;

AppMenu.defaultProps = {
  model: null,
  onMenuItemClick: null,
  onRootMenuItemClick: null,
  layoutMode: null,
  active: false,
};

AppMenu.propTypes = {
  model: PropTypes.array,
  layoutMode: PropTypes.string,
  onMenuItemClick: PropTypes.func,
  onRootMenuItemClick: PropTypes.func,
  active: PropTypes.bool,
};
