import Link from 'next/link';

const Logo = () => {
  return (
    <div className="logo">
      <Link href="/">
        <a>
          <img src="/images/ringil-black.svg" alt="Logo" style={{ height: '40px' }} />
        </a>
      </Link>
    </div>
  );
};

export default Logo;
