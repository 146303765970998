import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import AppTopbar from '@components/AppTopbar';
import AppMenu from '@components/AppMenu';
import Head from 'next/head';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'next-i18next';
import { AuthenticatedUserContext } from './AuthenticatedUser/AuthenticatedUser';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import { GetPowerBIReportServiceResponse, ModuleTypeEnum, PermissionTypesEnum } from '../api/logsteo-api.v2';
import { useTopInformation } from './TopInformation/TopInformation';
import styled from 'styled-components';
import { ApiContext } from '../api/api';
import PermissionError from './PermissionError/PermissionError';
import useNavigation from '@hooks/useNavigation/useNavigation';

interface Menu {
  label: string;
  to?: string;
  icon?: string;
  render: boolean;
  items?: Menu[];
  withMargin?: boolean;
}

interface ComponentProps {
  title?: string;
  oneOfPermission?: PermissionTypesEnum[];
  withMargin?: boolean;
}

const Layout: React.FC<PropsWithChildren<ComponentProps>> = ({
  children,
  title = 'Next App',
  oneOfPermission,
  withMargin = true,
}) => {
  const i18n = useTranslation('common');
  const t = i18n.t;

  const { loggedUser, hasModule, hasPermission, hasPermissionAndModule } = useContext(AuthenticatedUserContext);
  const profileMode = 'inline';
  const darkMenu = false;
  const layoutMode = 'static';

  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [rotateMenuButton, setRotateMenuButton] = useState(false);
  const [topbarMenuActive, setTopbarMenuActive] = useState(false);
  const [activeTopbarItem, setActiveTopbarItem] = useState(null);
  const [rightPanelActive, setRightPanelActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [menuClick, setMenuClick] = useState(false);
  const [rightPanelClick, setRightPanelClick] = useState(false);

  const [topbarItemClick, setTopbarItemClick] = useState(false);
  const navigation = useNavigation();

  const { listPowerBIReports } = useContext(ApiContext);
  const [reports, setReports] = useState<GetPowerBIReportServiceResponse>();
  useEffect(() => {
    listPowerBIReports((d) => {
      setReports(d);
    });
  }, []);

  const isDesktop = () => {
    return window.innerWidth > 1024;
  };

  const nav = useNavigation();

  const hideOverlayMenu = () => {
    setRotateMenuButton(false);
    setOverlayMenuActive(false);
  };

  const onMenuButtonClick = (event) => {
    setMenuClick(true);

    setRotateMenuButton(!rotateMenuButton);

    setTopbarMenuActive(false);

    if (isDesktop()) {
      setStaticMenuDesktopInactive(!staticMenuDesktopInactive);
    } else {
      setStaticMenuMobileActive(!staticMenuMobileActive);
    }

    event.preventDefault();
  };

  const onTopbarMenuButtonClick = (event) => {
    setTopbarMenuActive(true);

    setStaticMenuMobileActive(false);

    hideOverlayMenu();
    event.preventDefault();
  };

  const onTopbarItemClick = (event) => {
    event.originalEvent.stopPropagation();
    // setTopbarMenuActive(true);

    if (activeTopbarItem === event.item) {
      setActiveTopbarItem(null);
    } else {
      setActiveTopbarItem(event.item);
    }

    event.originalEvent.preventDefault();
  };

  const onRightPanelButtonClick = (event) => {
    setRightPanelClick(true);
    setRightPanelActive(!rightPanelActive);
    event.preventDefault();
  };

  const onMenuClick = () => {
    setMenuClick(true);
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      hideOverlayMenu();
    }

    if (!event.item.items) {
      setMenuActive(false);
    }
  };

  const onRootMenuItemClick = (event) => {
    event.originalEvent.stopPropagation();
  };

  const menuClassName = classNames('layout-menu', {
    'layout-menu-dark': darkMenu,
  });

  const generateReportsMenu = (): Menu => {
    if (isNullOrUndefined(reports)) return null;

    if (reports?.reports?.length == 0) return null;

    return {
      label: t('Layout.reports', 'Reports'),
      render: true,
      items: reports?.reports.map((t) => {
        return {
          label: t.reportName,
          icon: 'pi pi-id-card',
          /* to: `/reports/${t.reportId}`,*/
          to: navigation.createNavigationLink(navigation.urlFunctions.createReportUrl(t.reportId)),
          render: true,
        };
      }),
    };
  };

  const menuCustomer: Menu[] = [
    {
      label: t('Layout.expedition', 'EXPEDICE'),
      render: true,
      items: [
        {
          label: t('Layout.newExpedition', 'Nová'),
          icon: 'pi pi-plus',
          //to: '/customer/expedition',
          to: nav.createNavigationLink(nav.urlFunctions.createCustomerNewExpedition()),
          render: true,
        },
        {
          label: t('Layout.allExpeditions', 'Všechny'),
          icon: 'pi pi-table',
          to: nav.createNavigationLink(nav.urlFunctions.createCustomerExpeditionList()),
          render: true,
        },
      ],
    },
    {
      label: t('Layout.network', 'SÍŤ'),
      render: true,
      items: [
        {
          label: t('Layout.routes', 'Trasy'),
          icon: 'pi pi-share-alt',
          /*to: '/customer/routes',*/
          to: nav.createNavigationLink(nav.urlFunctions.createCustomerExpeditionTemplates()),
          render: true,
        },
        {
          label: t('Layout.myCarriers', 'Moji dopravci'),
          icon: 'pi pi-id-card',
          //to: '/customer/carriers',
          to: nav.createNavigationLink(nav.urlFunctions.createMyCarrierList()),
          render: true,
        },
        {
          label: t('Layout.carrierGroups', 'Skupiny dopravců'),
          icon: 'pi pi-list',
          to: nav.createNavigationLink(nav.urlFunctions.createCarrierGroups()),
          render: true,
        },
        {
          label: t('Layout.partners', 'Partneři'),
          icon: 'pi pi-globe',
          to: nav.createNavigationLink(nav.urlFunctions.createPartnerList()),
          render: true,
        },
        {
          label: t('Layout.carrierCatalog', 'Katalog dopravců'),
          icon: 'pi pi-id-card',
          //to: '/customer/carrier-catalog',
          to: nav.createNavigationLink(nav.urlFunctions.createCarrierCatalogList()),
          render: true,
        },
      ],
    },
  ];

  const generateMenu = (): Menu[] => {
    let menus: Menu[] = [
      {
        label: t('Layout.dashboard', 'DASHBOARD'),
        render: true,
        items: [
          {
            label: t('Layout.basic', 'Basic'),
            icon: 'pi pi-plus',
            /*to: '/',*/
            to: nav.createNavigationLink(nav.urlFunctions.createHomePage()),
            render: true,
          },
        ],
      },
    ];

    if (hasModule(ModuleTypeEnum.EXPEDITION, null)) {
      menus = [...menus, ...menuCustomer];
    }

    menus = [...menus, ...[generateReportsMenu()]];

    if (hasModule(ModuleTypeEnum.CARRIER, null)) {
      menus = [
        ...menus,
        ...[
          {
            label: t('Layout.demands', 'POPTÁVKY'),

            render: true,
            items: [
              {
                label: t('Layout.allDemands', 'Seznam všech'),
                icon: 'pi pi-plus',
                /*to: '/carrier/demands',*/
                to: nav.createNavigationLink(nav.urlFunctions.createCarrierDemandsList()),
                render: true,
              },
              {
                label: t('Layout.allMultiDemands', 'Multi demands'),
                icon: 'pi pi-plus',
                /*to: '/carrier/multi-demands',*/
                to: nav.createNavigationLink(nav.urlFunctions.createCarrierMultiDemandsList()),
                render: true,
              },
            ],
          },
          {
            label: t('Layout.shipments', 'PŘEPRAVY'),
            render: true,
            items: [
              {
                label: t('Layout.allShipments', 'Všechny přiřazené'),
                icon: 'pi pi-share-alt',
                /*to: '/carrier/shipments',*/
                to: nav.createNavigationLink(nav.urlFunctions.createCarrierShipmentsList()),
                render: true,
              },
            ],
          },
        ],
      ];
    }

    if (
      hasPermissionAndModule(ModuleTypeEnum.TIMESLOTS, null, [
        PermissionTypesEnum.PERMISSION_TIMESLOTS,
        PermissionTypesEnum.PERMISSION_TIMESLOT_READ,
        PermissionTypesEnum.PERMISSION_TIMESLOT_FULLREAD,
        PermissionTypesEnum.PERMISSION_TIMESLOT_CALENDAR,
      ])
    ) {
      menus = [
        ...menus,
        ...[
          {
            label: t('Layout.timeslots', 'Timesloty'),
            // @ts-ignore - proc?
            requiredModule: ModuleTypeEnum.TIMESLOTS,
            render: true,
            items: [
              {
                label: t('Layout.timeSlotsDashboard', 'Dashboard timeslotů'),
                icon: 'pi pi-table',
                /*to: '/timeslots/calendar',*/
                to: navigation.createNavigationLink(navigation.urlFunctions.createTimeslotCalendar()),
                render:
                  hasModule(ModuleTypeEnum.TIMESLOTS, false) &&
                  hasPermission([
                    PermissionTypesEnum.PERMISSION_TIMESLOT_CALENDAR,
                    PermissionTypesEnum.PERMISSION_TIMESLOTS,
                  ]),
              },

              {
                label: t('Layout.reservation', 'Rezervace'),
                icon: 'pi pi-id-card',
                /*to: '/timeslots/reservations',*/
                to: navigation.createNavigationLink(navigation.urlFunctions.createReservationList()),
                render: hasPermission([
                  PermissionTypesEnum.PERMISSION_TIMESLOT_READ,
                  PermissionTypesEnum.PERMISSION_TIMESLOT_FULLREAD,
                  PermissionTypesEnum.PERMISSION_TIMESLOTS,
                ]),
              },
            ],
          },
        ],
      ];
    }
    /*if (hasModule(ModuleTypeEnum.SUPPLIER_MANIFEST, null)) {
              menus = [
                ...menus,
                ...[
                  {
                    label: t('Layout.supplierManifest', 'Supplier manifest'),
                    render: true,
                    items: [
                      {
                        label: t('Layout.listSupplierManifest', 'All supplier manifests'),
                        icon: 'pi pi-table',
                        to: '/supplier-manifest/list',
                        render: true,
                      },
                    ],
                  },
                ],
              ];
            }*/

    if (hasModule(ModuleTypeEnum.SUPPLIER_NOTIFICATION, null)) {
      menus = [
        ...menus,
        ...[
          {
            label: t('Layout.supplierNotification', 'Supplier notification'),
            render: true,
            items: [
              {
                label: t('Layout.listSupplierNotifications', 'All supplier notifications'),
                icon: 'pi pi-table',
                /*to: '/supplier-notifications',*/
                to: nav.createNavigationLink(nav.urlFunctions.createSupplierNotificationList()),
                render: true,
              },
              hasModule(ModuleTypeEnum.SUPPLIER_NOTIFICATION, false)
                ? {
                    label: t('Layout.customerAllMultiDemands', 'Multi demands'),
                    icon: 'pi pi-plus',
                    /*to: '/multi-demands',*/
                    to: nav.createNavigationLink(nav.urlFunctions.createMultiDemansList()),
                    render: true,
                  }
                : null,
            ].filter((t) => t !== null),
          },
        ],
      ];
    }

    if (hasModule(ModuleTypeEnum.SHIPMENT, null)) {
      menus = [
        ...menus,
        ...[
          {
            label: t('Layout.shipmentsNew', 'Supplier shipments new'),
            render: true,
            items: [
              {
                label: t('Layout.listSupplierShipments', 'All supplier shipments'),
                icon: 'pi pi-table',
                /*to: '/shipments',*/
                to: nav.createNavigationLink(nav.urlFunctions.createShipmentList()),
                render: true,
              },
            ],
          },
        ],
      ];
    }

    if (
      hasPermission([
        PermissionTypesEnum.PERMISSION_PARTNERS,
        PermissionTypesEnum.PERMISSION_LOCATIONS,
        PermissionTypesEnum.PERMISSION_COMPANY_PERMISSIONS,
      ])
    ) {
      menus = [
        ...menus,
        ...[
          {
            label: t('Layout.settings', 'Settings'),
            render: true,
            items: [
              {
                label: t('Layout.locations', 'Lokality'),
                icon: 'pi pi-map',
                /*to: '/customer/locations',*/
                to: nav.createNavigationLink(nav.urlFunctions.createLocationsList()),
                render: hasPermission([PermissionTypesEnum.PERMISSION_LOCATIONS]),
              },
              {
                label: t('Layout.exceptions', 'Výjimky'),
                icon: 'pi pi-sort-alt',
                /*to: '/customer/exceptions',*/
                to: nav.createNavigationLink(nav.urlFunctions.createExceptionList()),
                render: hasPermission([PermissionTypesEnum.PERMISSION_EXCEPTIONS]),
              },
              {
                label: t('Layout.partners', 'Partneři'),
                icon: 'pi pi-globe',
                /*to: '/customer/partners',*/
                to: nav.createNavigationLink(nav.urlFunctions.createPartnerList()),
                render: hasPermission([PermissionTypesEnum.PERMISSION_PARTNERS]),
              },
              /*              {
                label: t('Layout.permission', 'Permissions'),
                icon: 'pi pi-wrench',
                to: '/customer/permissions',
                render: hasPermission([PermissionTypesEnum.PERMISSION_COMPANY_PERMISSIONS]),
              },*/

              /*{
                                            label: t('Layout.additionalTerms', 'Additional terms'),
                                            icon: 'pi pi-globe',
                                            to: '/additional-terms',
                                            render: true,
                                          },*/
            ],
          },
        ],
      ];
    }

    return menus;
  };

  const layoutContainerClassName = classNames('layout-container', {
    'menu-layout-static': layoutMode,
    'layout-menu-overlay-active': overlayMenuActive,
    'layout-menu-static-inactive': staticMenuDesktopInactive,
    'layout-menu-static-active': staticMenuMobileActive,
  });

  const { user } = useAuth0();

  const onDocumentClick = () => {
    if (!topbarItemClick) {
      setActiveTopbarItem(null);
    }
    if (topbarMenuActive) {
      setTopbarMenuActive(false);
      setTopbarItemClick(false);
    }
  };

  const { versionInfo, TopInformation } = useTopInformation();

  if (oneOfPermission?.length > 0 && isNullOrUndefined(loggedUser)) return <>Loading...</>;
  if (oneOfPermission?.length > 0 && !hasPermission(oneOfPermission))
    return (
      <>
        <PermissionError
          permission={[PermissionTypesEnum.PERMISSION_TIMESLOT_READ, PermissionTypesEnum.PERMISSION_TIMESLOT_FULLREAD]}
        />
      </>
    );

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <TopInformation />

      <div className="layout-wrapper" onClick={onDocumentClick}>
        <div className={layoutContainerClassName}>
          <AppTopbar
            profileMode={profileMode}
            topbarMenuActive={topbarMenuActive}
            activeTopbarItem={activeTopbarItem}
            onMenuButtonClick={onMenuButtonClick}
            onTopbarMenuButtonClick={onTopbarMenuButtonClick}
            onTopbarItemClick={onTopbarItemClick}
            onRightPanelButtonClick={onRightPanelButtonClick}
            horizontal={false}
          />
          <div className={menuClassName} onClick={onMenuClick}>
            <div className="menu-scroll-content">
              <AppMenu
                model={generateMenu()}
                onMenuItemClick={onMenuItemClick}
                onRootMenuItemClick={onRootMenuItemClick}
                layoutMode={layoutMode}
                active={menuActive}
              />
              <Version>{versionInfo?.commit_message}</Version>
            </div>
          </div>
          <div className="layout-main">
            {withMargin ? (
              <div className="layout-content">
                <div className="p-grid">
                  <div className="p-col-12">{children}</div>
                </div>
              </div>
            ) : (
              <div>{children}</div>
            )}
          </div>
          <div className="layout-mask"></div>
        </div>
      </div>
    </>
  );
};

const Version = styled.div`
  display: flex;
  padding: 1rem;
  color: white;
`;

export default Layout;
