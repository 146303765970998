import React, {useContext, useEffect, useState} from 'react';
import {EntityTypeEnum, MessageTypeFilterEnum, PageResponseNotificationPreviewListOutDTO,} from 'api/logsteo-api.v2';
import {Button} from 'primereact/button';
import {dateFormatDateTime, mapFromAPIDateTime} from 'utils/date';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import {ApiContext} from '../../../../../api/api';

interface ComponentProps {
  visible: boolean;
  onClick: (e) => void;
}

const AppMenuNotification: React.FC<ComponentProps> = ({ visible, onClick }) => {
  const { cuListNotifications, cuReadNotification } = useContext(ApiContext);
  const [state, setState] = useState<PageResponseNotificationPreviewListOutDTO>();
  const router = useRouter();
  const { i18n, t } = useTranslation('common');

  useEffect(() => {
    cuListNotifications({ messageTypeFilter: MessageTypeFilterEnum.UNREAD }, { pageSize: 5, pageNumber: 0 }, (data) => {
      setState(data);
    });
  }, []);

  const maskAsReadAndRedirect = (notificationId: string, companyId: string, applicationId: string, entityId: string, entityType: EntityTypeEnum) => {
    // FIXME - mvlach - use nextjs router.push
    cuReadNotification(notificationId, companyId, applicationId, () => {
      /*      router.replace(`/customer/expedition/${applicationId}`, `/customer/expedition/${applicationId}`, {
        shallow: false,
      });*/
      if (entityType == EntityTypeEnum.SUPPLIER_NOTIFICATION) {
        window.location.href = `/${i18n.language}/supplier-notifications/${entityId}`;
      } else if (entityType == EntityTypeEnum.GROUP_DEMAND) {
        window.location.href = `/${i18n.language}/multi-demands/${entityId}`;
      } else {
        window.location.href = `/${i18n.language}/customer/expedition/${applicationId}`;
      }
    });
  };

  return (
    <>
      <li className={`${visible ? 'active-top-menu' : ''}`}>
        <button className="p-link" onClick={onClick}>
          <i className="topbar-icon material-icons animated swing">message</i>
          {state && state.total > 0 && <span className="topbar-badge animated rubberBand">{state.total}</span>}
          <span className="topbar-item-name">{t(`AppMenuNotification.messages`, `Messages`)}</span>
        </button>
        {state && state.data && (
          <ul className="ultima-menu animated fadeInDown">
            {state.data.map((n, index) => {
              return (
                <li role="menuitem" key={index}>
                  <button
                    className="p-link topbar-message"
                    onClick={() => maskAsReadAndRedirect(n.id, n.companyId, n.applicationId, n.entityId, n.entityType)}
                  >
                    <div>
                      <div className="p-d-flex">
                        <div className={'p-mr-2'}>{n.applicationId}</div>
                        <div className="">
                          <small className="text-gray5">{dateFormatDateTime(mapFromAPIDateTime(n.createAt))}</small>
                        </div>
                      </div>
                      <div>{n.name}</div>
                    </div>
                  </button>
                </li>
              );
            })}
            <div className="p-d-flex p-jc-center p-mb-2">
              <Button
                label={t(`AppMenuNotification.seeAll`, `Vše`)}
                className="p-button-text"
                onClick={(e) => router.push('/customer/notifications')}
              />
            </div>
          </ul>
        )}
      </li>
    </>
  );
};

export default AppMenuNotification;
