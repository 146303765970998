import { useRouter } from 'next/router';

interface ComponentProps {}

interface Params {
  applicationParams?: { [key: string]: string };
  queryParams?: { [key: string]: string };
}

interface URLInfo {
  pattern: string;
  applicationParams?: { [key: string]: string };
  queryParams?: { [key: string]: string };
}

const urlFunctions = {
  createCustomerExpeditionDetail: (expeditionApplicationID: string): URLInfo => {
    return {
      pattern: '/customer/expedition/{expeditionApplicationId}',
      queryParams: {},
      applicationParams: { expeditionApplicationId: expeditionApplicationID },
    };
  },
  createSupplierManifestDetail: (supplierManifestId: string): URLInfo => {
    return {
      pattern: '/supplier-manifest/{supplierManifestId}',
      queryParams: {},
      applicationParams: { supplierManifestId: supplierManifestId },
    };
  },
  createSupplierNotificationDetail: (supplierNotificationId: string): URLInfo => {
    return {
      pattern: '/supplier-notifications/{supplierNotificationId}',
      queryParams: {},
      applicationParams: { supplierNotificationId: supplierNotificationId },
    };
  },
  createShipmentDetail: (shipmentAppId: string): URLInfo => {
    return {
      pattern: '/shipments/{shipmentAppId}',
      queryParams: {},
      applicationParams: { shipmentAppId: shipmentAppId },
    };
  },
  createCustomerGroupDemandDetail: (groupDemandId: string): URLInfo => {
    return {
      pattern: '/multi-demands/{groupDemandId}',
      queryParams: {},
      applicationParams: { groupDemandId: groupDemandId },
    };
  },
  createCustomerListGroupDemands: (): URLInfo => {
    return {
      pattern: '/multi-demands',
      queryParams: {},
      applicationParams: {},
    };
  },
  createCMRDetail: (expeditionId: string): URLInfo => {
    return {
      pattern: '/cmr/{expeditionId}',
      queryParams: {},
      applicationParams: { expeditionId: expeditionId },
    };
  },
  createCustomerExpeditionList: (queryParams?: { [key: string]: any }): URLInfo => {
    return {
      pattern: '/customer/expeditions',
      queryParams: queryParams,
      applicationParams: {},
    };
  },
  createCustomerNewExpedition: (): URLInfo => {
    return {
      pattern: '/customer/expedition',
      queryParams: {},
      applicationParams: {},
    };
  },
  createMyCarrierList: (): URLInfo => {
    return {
      pattern: '/customer/carriers',
      queryParams: {},
      applicationParams: {},
    };
  },
  createCustomerExpeditionTemplates: () => {
    return {
      pattern: '/customer/routes',
      queryParams: {},
      applicationParams: {},
    };
  },
  createCarrierGroups: () => {
    return {
      pattern: '/customer/carrier-groups',
      queryParams: {},
      applicationParams: {},
    };
  },
  createCarrierGroupDetail: (carrierGroupId: string) => {
    return {
      pattern: '/customer/carrier-group/{carrierGroupId}',
      queryParams: {},
      applicationParams: { carrierGroupId },
    };
  },
  createPartnerList: () => {
    return {
      pattern: '/customer/partners',
      queryParams: {},
      applicationParams: {},
    };
  },
  createCarrierCatalogList: () => {
    return {
      pattern: '/customer/carrier-catalog',
      queryParams: {},
      applicationParams: {},
    };
  },
  createSupplierNotificationList: () => {
    return {
      pattern: '/supplier-notifications',
      queryParams: {},
      applicationParams: {},
    };
  },
  createMultiDemansList: () => {
    return {
      pattern: '/multi-demands',
      queryParams: {},
      applicationParams: {},
    };
  },
  createShipmentList: (queryParams?: { [key: string]: any }) => {
    return {
      pattern: '/shipments',
      queryParams: queryParams,
      applicationParams: {},
    };
  },
  createLocationsList: () => {
    return {
      pattern: '/customer/locations',
      queryParams: {},
      applicationParams: {},
    };
  },
  createExceptionList: (queryParams?: { [key: string]: any }) => {
    return {
      pattern: '/customer/exceptions',
      queryParams: queryParams,
      applicationParams: {},
    };
  },
  createCarrierDetail: (carrierId: string) => {
    return {
      pattern: '/customer/carrier/{carrierId}',
      queryParams: {},
      applicationParams: { carrierId },
    };
  },
  createPartnerDetail: (partnerId: string) => {
    return {
      pattern: '/customer/partner/{partnerId}',
      queryParams: {},
      applicationParams: { partnerId },
    };
  },
  createReportUrl: (reportId: string) => {
    return {
      pattern: '/reports/{reportId}',
      queryParams: {},
      applicationParams: { reportId },
    };
  },
  createTimeslotCalendar: () => {
    return {
      pattern: '/timeslots/calendar',
      queryParams: {},
      applicationParams: {},
    };
  },
  createReservationList: () => {
    return {
      pattern: '/timeslots/reservations',
      queryParams: {},
      applicationParams: {},
    };
  },
  createLocalityDetail: (localitylId: string) => {
    return {
      pattern: '/customer/locations/{localitylId}',
      queryParams: {},
      applicationParams: { localitylId },
    };
  },
  createProcessShipment: (fromShipmentId: string) => {
    return {
      pattern: '/supplier-notifications/process-shipment',
      queryParams: { fromShipmentId },
      applicationParams: {},
    };
  },
  createCarrierShipmentDetail: (companyId: string, applicationId: string) => {
    return {
      pattern: '/carrier/shipment/{companyId}-{applicationId}',
      queryParams: {},
      applicationParams: { companyId, applicationId },
    };
  },
  createCarrierDemandDetail: (companyId: string, applicationId: string) => {
    return {
      pattern: '/carrier/demand/{companyId}-{applicationId}',
      queryParams: {},
      applicationParams: { companyId, applicationId },
    };
  },
  createCustomerExpeditionTemplateDetail: (expeditionTemplateId: string) => {
    return {
      pattern: '/customer/route/{expeditionTemplateId}',
      queryParams: {},
      applicationParams: { expeditionTemplateId },
    };
  },
  createCarrierMultiDemandDetail: (groupDemandId: string) => {
    return {
      pattern: '/carrier/multi-demands/{groupDemandId}',
      queryParams: {},
      applicationParams: { groupDemandId },
    };
  },
  createMultiDemanDetail: (groupDemandId: string) => {
    return {
      pattern: '/multi-demands/{groupDemandId}',
      queryParams: {},
      applicationParams: { groupDemandId },
    };
  },
  createReservationDetail: (reservationId: string) => {
    return {
      pattern: '/timeslots/{reservationId}',
      queryParams: {},
      applicationParams: { reservationId },
    };
  },
  createCarrierMultiDemandsList: (queryParams?: { [key: string]: any }) => {
    return {
      pattern: '/carrier/multi-demands',
      queryParams: queryParams,
      applicationParams: {},
    };
  },
  createReservation: () => {
    return {
      pattern: '/timeslots/create-reservation',
      queryParams: {},
      applicationParams: {},
    };
  },
  createCarrierDemandsList: (queryParams?: { [key: string]: any }) => {
    return {
      pattern: '/carrier/demands',
      queryParams: queryParams,
      applicationParams: {},
    };
  },
  createCarrierShipmentsList: (queryParams?: { [key: string]: any }) => {
    return {
      pattern: '/carrier/shipments',
      queryParams: queryParams,
      applicationParams: {},
    };
  },
  createMyProfile: () => {
    return {
      pattern: '/my-profile',
      queryParams: {},
      applicationParams: {},
    };
  },
  createCompanyProfile: () => {
    return {
      pattern: '/company-profile',
      queryParams: {},
      applicationParams: {},
    };
  },
  createIhneritedCompanies: () => {
    return {
      pattern: '/inherited-companies',
      queryParams: {},
      applicationParams: {},
    };
  },
  createSettings: () => {
    return {
      pattern: '/settings',
      queryParams: {},
      applicationParams: {},
    };
  },
  createCreateReservationFromReservation: (fromReservation: string) => {
    return {
      pattern: '/timeslots/create-reservation',
      queryParams: { fromReservation },
      applicationParams: {},
    };
  },
  createShipmentCreate: () => {
    return {
      pattern: '/shipments/create-shipment',
      queryParams: {},
      applicationParams: {},
    };
  },
  createSupplierNotificationFromShipment: (fromShipmentId: string) => {
    return {
      pattern: '/supplier-notifications/process-shipment',
      queryParams: { fromShipmentId },
      applicationParams: {},
    };
  },
  createSupplierNotificationCreate: () => {
    return {
      pattern: '/supplier-notifications/create-supplier-notification',
      queryParams: {},
      applicationParams: {},
    };
  },
  createSupplierNotificationFromSupplierNotification: (fromSupplierNotification: string) => {
    return {
      pattern: '/customer/process-supplier-notification',
      queryParams: { fromSupplierNotification },
      applicationParams: {},
    };
  },
  createHomePage: () => {
    return {
      pattern: '/',
      queryParams: {},
      applicationParams: {},
    };
  },
  createExpeditionDetailV2: (expeditionId: string) => {
    return {
      pattern: '/test/expedition/{expeditionId}',
      queryParams: {},
      applicationParams: { expeditionId },
    };
  },
};

const useNavigation = () => {
  const router = useRouter();
  const runAs = router.query['runAs'] as string;

  const navigate = (urlInfo: URLInfo) => {
    const url = interpolate(urlInfo.pattern, {
      applicationParams: urlInfo.applicationParams,
      queryParams: { ...urlInfo.queryParams, runAs: runAs },
    });
    router.push(url);
  };

  const createNavigationLink = (urlInfo: URLInfo) => {
    const url = interpolate(urlInfo.pattern, {
      applicationParams: urlInfo.applicationParams,
      queryParams: { ...urlInfo.queryParams, runAs: runAs },
    });
    return url;
  };

  const hardNavigate = (urlInfo: URLInfo) => {
    const url = interpolate(urlInfo.pattern, {
      applicationParams: urlInfo.applicationParams,
      queryParams: urlInfo.queryParams,
    });
    window.location.href = url;
  };

  const silentNavigate = (urlInfo: URLInfo) => {
    const url = interpolate(urlInfo.pattern, {
      applicationParams: urlInfo.applicationParams,
      queryParams: urlInfo.queryParams,
    });
    router.push(url, url, { shallow: true });
  };

  return {
    navigate,
    createNavigationLink,
    hardNavigate,
    silentNavigate,
    urlFunctions,
  };
};

const interpolate = (val: string, params: Params) => {
  const queryParams = buildParams(params.queryParams);

  if (Object.keys(params.applicationParams || {}).length == 0) return `${val}?${queryParams}`;
  const interpolatedString = Object.keys(params.applicationParams).reduce((res, item) => {
    return res.replace(`{${item}}`, params.applicationParams[item]);
  }, val);

  return `${interpolatedString}?${queryParams}`;
};

const buildParams = (data) => {
  const params = new URLSearchParams();

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((value) => params.append(key, value.toString()));
    } else {
      if (value) params.append(key, value?.toString());
    }
  });

  return params.toString();
};
export default useNavigation;
