import classNames from 'classnames';
import Logo from './Logo/Logo';
import AppMenuNotification from 'components/pages/customer/AllNotificationsList/AppMenuNotification/AppMenuNotification';
import { useRouter } from 'next/router';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'next-i18next';
import { useContext } from 'react';
import { AuthenticatedUserContext } from './AuthenticatedUser/AuthenticatedUser';
import { useAuth0 } from '@auth0/auth0-react';
import useNavigation from '@hooks/useNavigation/useNavigation';

interface AppToolbarType {
  onMenuButtonClick: any;
  onTopbarMenuButtonClick: any;
  onTopbarItemClick: any;
  profileMode: string;
  horizontal: boolean;
  topbarMenuActive: boolean;
  activeTopbarItem: string;
  onRightPanelButtonClick: any;
}

const AppTopbar = ({
  onMenuButtonClick,
  onTopbarMenuButtonClick,
  onTopbarItemClick,
  profileMode,
  horizontal,
  topbarMenuActive,
  activeTopbarItem,
  onRightPanelButtonClick,
}: AppToolbarType) => {
  const { logout } = useAuth0();
  const navigation = useNavigation();
  const router = useRouter();

  const { t, i18n } = useTranslation('common');
  const onItemClick = (e, item) => {
    if (onTopbarItemClick) {
      onTopbarItemClick({
        originalEvent: e,
        item: item,
      });
    }
  };

  let topbarItemsClassName = classNames('topbar-items animated fadeInDown', {
    'topbar-items-visible': topbarMenuActive,
  });

  const { isCustomer, isCustomerAdmin, isCarrier, loggedUser, isAdmin, isCarrierAdmin, isCompanyAdmin } =
    useContext(AuthenticatedUserContext);

  const languages = [
    { name: t(`AppTopbar.czech`, `Czech`), code: 'cs', countryCode: 'cz' },
    { name: t('AppTopbar.english', 'English'), code: 'en', countryCode: 'gb' },
    { name: t('AppTopbar.german', 'Deutsch'), code: 'de', countryCode: 'de' },
    { name: t('AppTopbar.hungarian', 'Hungrarian'), code: 'hu', countryCode: 'hu' },
    { name: t('AppTopbar.poland', 'Polish'), code: 'pl', countryCode: 'pl' },
  ];

  const changeLanguage = (selectedLocale: string) => {
    const currentPath = router.asPath;
    router.push(currentPath, currentPath, { locale: selectedLocale });
  };

  return (
    <div className="topbar clearfix">
      <div className="topbar-left">
        <Logo />
      </div>

      <div className="topbar-right">
        <button className="p-link" id="menu-button" onClick={onMenuButtonClick}>
          <i></i>
        </button>
        <button className="p-link" id="topbar-menu-button" onClick={onTopbarMenuButtonClick}>
          <i className="material-icons">menu</i>
        </button>
        <div className="topbar-right-item">
          <ul className={topbarItemsClassName}>
            {(profileMode === 'top' || horizontal) && (
              <li
                className={classNames('profile-item', {
                  'active-top-menu': activeTopbarItem === 'profile',
                })}
              >
                <button className="p-link" onClick={(e) => onItemClick(e, 'profile')}>
                  <img className="profile-image" src="/layout/images/avatar.png" alt="Profile" />
                  <span className="topbar-item-name">Jane Williams</span>
                </button>

                <ul className="ultima-menu animated fadeInDown">
                  <li role="menuitem">
                    <button
                      className="p-link"
                      onClick={() => {
                        /*router.push('/my-profile');*/
                        navigation.navigate(navigation.urlFunctions.createMyProfile());
                      }}
                    >
                      <i className="material-icons">person</i>
                      <span>{t(`AppTopbar.profile`, `Profile`)}</span>
                    </button>
                  </li>
                  <li role="menuitem">
                    <button className="p-link" onClick={(e) => logout({ returnTo: window.origin })}>
                      <i className="material-icons">power_settings_new</i>
                      <span>{t(`AppTopbar.logout`, `Logout`)}</span>
                    </button>
                  </li>
                </ul>
              </li>
            )}

            <li
              className={classNames({
                'active-top-menu': activeTopbarItem === 'profile',
              })}
            >
              <button className="p-link profile-btn" onClick={(e) => onItemClick(e, 'profile')}>
                <img className="profile-image" src={loggedUser?.avatar} alt="Profile" />
                <span className="topbar-item-name">
                  {loggedUser?.firstName} {loggedUser?.lastName} ({loggedUser?.company})
                  <br />
                </span>
              </button>
              <ul className="ultima-menu animated fadeInDown">
                <li role="menuitem">
                  <button
                    className="p-link"
                    onClick={() => {
                      /*router.push('/my-profile');*/
                      navigation.navigate(navigation.urlFunctions.createMyProfile());
                    }}
                  >
                    <i className="material-icons">person</i>
                    <span>{t(`AppTopbar.profile`, `Profile`)}</span>
                  </button>
                </li>
                {/*              <li role="menuitem">
                <button className="p-link">
                  <i className="material-icons">security</i>
                  <span>Privacy</span>
                </button>
              </li>
              <li role="menuitem">
                <button className="p-link">
                  <i className="material-icons">settings_applications</i>
                  <span>Settings</span>
                </button>
              </li>*/}
                {(isCustomerAdmin() || isCarrierAdmin() || isCompanyAdmin()) && (
                  <li role="menuitem">
                    <button
                      className="p-link"
                      onClick={(e) => {
                        /*router.push('/company-profile');*/
                        navigation.navigate(navigation.urlFunctions.createCompanyProfile());
                      }}
                    >
                      <i className="material-icons">settings_applications</i>
                      <span>{t(`AppTopbar.companyProfile`, `Company Profile`)}</span>
                    </button>
                  </li>
                )}

                {(isCustomerAdmin() || isCarrierAdmin() || isCompanyAdmin()) && (
                  <li role="menuitem">
                    <button
                      className="p-link"
                      onClick={(e) => {
                        /* router.push('/inherited-companies');*/
                        navigation.navigate(navigation.urlFunctions.createIhneritedCompanies());
                      }}
                    >
                      <i className="material-icons">settings_applications</i>
                      <span>{t(`AppTopbar.inheritedCompanies`, `Inherited companies`)}</span>
                    </button>
                  </li>
                )}

                {(isCustomerAdmin() || isCompanyAdmin()) && (
                  <li role="menuitem">
                    <button
                      className="p-link"
                      onClick={(e) => {
                        /*router.push('/settings');*/
                        navigation.navigate(navigation.urlFunctions.createSettings());
                      }}
                    >
                      <i className="material-icons">settings_applications</i>
                      <span>{t(`AppTopbar.settings`, `Settings`)}</span>
                    </button>
                  </li>
                )}

                <li role="menuitem">
                  <button className="p-link" onClick={(e) => logout({ returnTo: window.origin })}>
                    <i className="material-icons">power_settings_new</i>
                    <span>{t(`AppTopbar.logout`, `Logout`)}</span>
                  </button>
                </li>
              </ul>
            </li>

            {/*       <li
            className={classNames({
              'active-top-menu': activeTopbarItem === 'settings',
            })}
          >
            <button className="p-link" onClick={(e) => onItemClick(e, 'settings')}>
              <i className="topbar-icon material-icons">settings</i>
              <span className="topbar-item-name">Settings</span>
            </button>
            <ul className="ultima-menu animated fadeInDown">
              <li role="menuitem">
                <button className="p-link">
                  <i className="material-icons">palette</i>
                  <span>Change Theme</span>
                </button>
              </li>
              <li role="menuitem">
                <button className="p-link">
                  <i className="material-icons">favorite_border</i>
                  <span>Favorites</span>
                </button>
              </li>
              <li role="menuitem">
                <button className="p-link">
                  <i className="material-icons">lock</i>
                  <span>Lock Screen</span>
                </button>
              </li>
              <li role="menuitem">
                <button className="p-link">
                  <i className="material-icons">wallpaper</i>
                  <span>Wallpaper</span>
                </button>
              </li>
            </ul>
          </li>*/}
            {isCustomer() && (
              <AppMenuNotification
                visible={activeTopbarItem === 'messages'}
                onClick={(e) => onItemClick(e, 'messages')}
              />
            )}

            <li
              className={classNames('search-item', {
                'active-top-menu': activeTopbarItem === 'search',
              })}
              onClick={(e) => onItemClick(e, 'search')}
            >
              {/*            <span className="md-inputfield p-input-icon-left tete">
              <i className="pi pi-search" />
              <InputText placeholder="Search" />
            </span>*/}
            </li>
          </ul>
          <Dropdown
            value={i18n.language}
            options={languages}
            onChange={(e) => changeLanguage(e.target.value)}
            optionLabel="name"
            optionValue="code"
            dataKey={'code'}
            className="language-switcher"
            valueTemplate={(option) => (
              <div className="language-item">
                <img src={`/images/flags/${option.countryCode}.svg`} alt={option.code} />
                <span>{option.name}</span>
              </div>
            )}
            itemTemplate={(option) => (
              <div className="language-item">
                <img src={`/images/flags/${option.countryCode}.svg`} alt={option.code} />
                <span>{option.name}</span>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

// @ts-ignore
export default AppTopbar;
